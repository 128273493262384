app.filter('showInOverview', function () {
  var filtered = []
  return function (items) {
    if (!items) { return }
    for (var i = 0; i < items.length; i++) {
      var item = items[i]
      if (item.showInOverview) {
        filtered.push(item)
      }
    }
  }
})
