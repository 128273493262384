prismanoteApp.filter('filterDateService', function () {
  return function (items, from, to, mode) {
    if (!items || items.length === 0) {
      return true
    }
    if (!to && !from && items) {
      return items
    }

    // mode can be intake, end of both
    // intake means filter on dateCreated
    // end means filter on [transaction.type].estimatedDate
    // and both, I'm not kidding you, on both
    var results = []
    var counter = 0
    for (var i = 0; i < items.length; i++) {
      var dc = items[i].dateCreated
      if (items[i][items[i].type] && items[i][items[i].type].estimatedDate) {
        var ed = items[i][items[i].type].estimatedDate
      }
      from = moment(new Date(from)).startOf('day')
      to = moment(new Date(to)).endOf('day')

      if (from.isValid() === false && to.isValid() === false) {
        results.push(items[i])
      } else {
        if (mode === 'intake') {
          if (from && to) {
            if (moment(dc).isAfter(from) && moment(dc).isBefore(to)) {
              results.push(items[i])
            }
          }
          if (from && to.isValid() === false) {
            if (moment(dc).isAfter(from)) {
              results.push(items[i])
            }
          }

          if (from.isValid() === false && to) {
            if (moment(dc).isBefore(to)) {
              results.push(items[i])
            }
          }
        }
        if (mode === 'end') {
          if (from && to) {
            if (moment(ed).isAfter(from) && moment(ed).isBefore(to)) {
              results.push(items[i])
            }
          }
          if (from && to.isValid() === false) {
            if (moment(ed).isAfter(from)) {
              results.push(items[i])
            }
          }
          if (from.isValid() === false && to) {
            if (moment(ed).isBefore(to)) {
              results.push(items[i])
            }
          }
        }
        if (mode === 'both') {
          if (from && to) {
            if ((moment(dc).isAfter(from) && moment(dc).isBefore(to)) &&
                            (moment(ed).isAfter(from) && moment(ed).isBefore(to))
            ) {
              results.push(items[i])
            }
          }

          if (from && to.isValid() === false) {
            if (moment(dc).isAfter(from) && moment(ed).isAfter(from)) {
              results.push(items[i])
            }
          }

          if (from.isValid() === false && to) {
            if (moment(dc).isBefore(to) && moment(ed).isBefore(to)) {
              results.push(items[i])
            }
          }
        }
      }
      counter++
      if (counter === items.length) {
        return results
      }
    }
  }
})

prismanoteApp.filter('filterDateTransaction', function () {
  return function (items, from, to, mode) {
    if (!items || items.length === 0) {
      return true
    }
    if (!to && !from && items) {
      return items
    }

    var results = []
    var counter = 0
    for (var i = 0; i < items.length; i++) {
      var dc = items[i].dateCreated

      from = moment(new Date(from)).startOf('day')
      to = moment(new Date(to)).endOf('day')

      if (from.isValid() === false && to.isValid() === false) {
        results.push(items[i])
      } else {
        if (moment(dc).isAfter(from) && moment(dc).isBefore(to)) {
          results.push(items[i])
        }
      }
      counter++
      if (counter === items.length) {
        return results
      }
    }
  }
})
